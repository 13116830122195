.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.signup-form input,
.signup-form button,
.signup-form h2,
.signup-form p {
  font-family: "Anton", sans-serif;
  text-transform: none; /* Optional for bold look */
  font-weight: bold;
}

body {
  font-family: "Anton", sans-serif;
  background-image: url("/public/whitebg.jpg");
  background-size: cover;
  background-position: center;
}

.signup-container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.signup-form input::placeholder {
  color: black;
  opacity: 1; /* Ensures full visibility in some browsers */
}

/* For better cross-browser support */
.signup-form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

.signup-form input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

.signup-form {
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack form fields vertically */
  align-items: center; /* Center items horizontally */
  width: 100%;
  max-width: 400px;
  font-family: "Anton"
}

.signup-form h2,
p {
  margin-bottom: 20px;
  color: rgb(2, 2, 2);
}

.signup-form input {
  width: 80%;
  padding: 10px;
  margin-bottom: 20px;
  border: solid black;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  outline: none;
}

.signup-form button {
  padding: 12px 20px;
  border: 2px solid black; /* Matches the placeholder text color */
  background-color: transparent;
  color: black; /* Ensures text is visible */
  border-radius: 0; /* Remove rounded corners if needed */
  font-size: 16px;
  font-family: "Anton", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: none; /* Remove any unwanted shadows */
}

.signup-form button:hover {
  background-color: black;
  color: white;
}

.signup-form p {
  margin-top: 5px; /* Reduce space above "SIGN UP FOR LATEST DROPS" */
}

@font-face {
  font-family: "DesirableCalligraphy";
  src: url("./fonts/Desirable Calligraphy.ttf") format("truetype"),
    url("./fonts/Desirable Calligraphy.otf") format("opentype");
}

@font-face {
  font-family: "DesirableSans";
  src: url("./fonts/Desirable Sans.ttf") format("truetype"),
    url("./fonts/Desirable Sans.otf") format("opentype");
}

@font-face {
  font-family: "Anton";
  src: url("./fonts/Anton-Regular.ttf") format("truetype");
}


h2 {
  color: white;
  font-size: 30px;
}

p {
  font-size: 30px;
}

@media (max-width: 768px) {
  .signup-form {
    width: 90%;
  }

  .signup-form input {
    width: 75%;
  }
}
